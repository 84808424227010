import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Customer from "../models/customer";

export const getCustomers = createAsyncThunk(
  "customer/getCustomers",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/customers?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const searchCustomer = createAsyncThunk(
  "customer/searchCustomer",
  async (customerFilter: any) => {
    const URIDecodedVendor = encodeURIComponent(JSON.stringify(customerFilter));
    try {
      const response = await API.get(
        `/customers?limit=all&filter=${URIDecodedVendor}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addCustomer = createAsyncThunk(
  "customer/addCustomer",
  async (customer: Customer, { rejectWithValue }) => {
    try {
      const response = await API.post("customers", customer);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (customer: Customer, { rejectWithValue }) => {
    try {
      const response = await API.put(`customers/${customer._id}`, customer);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (_id: string) => {
    try {
      const response = await API.delete(`customers/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCustomerCheckouts = createAsyncThunk(
  "customer/getCustomerCheckouts",
  async (getParams: any, { rejectWithValue }) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        customer: getParams.customer,
        vendor: getParams.vendor,
      })
    );
    try {
      const response = await API.get(
        `/checkout?limit=all&filter=${URIDecodedVendor}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerCheckoutHistory = createAsyncThunk(
  "customer/getCustomerCheckoutHistory",
  async (getParams: any, { rejectWithValue }) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        checkout: getParams.checkoutId,
      })
    );
    try {
      const response = await API.get(
        `/players-bucket?limit=all&filter=${URIDecodedVendor}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
