import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getInQueueSoftplayPlayers,
  getInQueuePlaystationPlayers,
  updatePlayerBucketSoftplay,
  updatePlayerBucketPlaystation,
} from "../services/playersBucketApi";

interface SalesPersonDashboardState {
  loading: boolean;
  error: string | null;
  playerBucketLoading: boolean;
  playersBucketSoftplay: any[];
  playersBucketPlaystation: any[];
}

const initialState = {
  loading: false,
  error: null,
  playerBucketLoading: false,
  playersBucketSoftplay: [],
  playersBucketPlaystation: [],
} as SalesPersonDashboardState;

const playersBucketSlice = createSlice({
  name: "playersBucket",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInQueueSoftplayPlayers.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.playersBucketSoftplay = [];
      })
      .addCase(
        getInQueueSoftplayPlayers.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.playersBucketSoftplay = action.payload;
        }
      )
      .addCase(
        getInQueueSoftplayPlayers.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      )

      .addCase(getInQueuePlaystationPlayers.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.playersBucketPlaystation = [];
      })
      .addCase(
        getInQueuePlaystationPlayers.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.playersBucketPlaystation = action.payload;
        }
      )
      .addCase(
        getInQueuePlaystationPlayers.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      )

      .addCase(updatePlayerBucketSoftplay.pending, (state, action) => {
        state.playerBucketLoading = true;
        state.error = null;
      })
      .addCase(
        updatePlayerBucketSoftplay.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.playerBucketLoading = false;
          state.error = null;

          state.playersBucketSoftplay = state.playersBucketSoftplay?.map(
            (playersBucketS) =>
              playersBucketS._id === action.payload._id
                ? action.payload
                : playersBucketS
          );
        }
      )
      .addCase(
        updatePlayerBucketSoftplay.rejected,
        (state, action: PayloadAction<any>) => {
          state.playerBucketLoading = false;
          state.error = action.payload;
        }
      )

      .addCase(updatePlayerBucketPlaystation.pending, (state, action) => {
        state.playerBucketLoading = true;
        state.error = null;
      })
      .addCase(
        updatePlayerBucketPlaystation.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.playerBucketLoading = false;
          state.error = null;

          state.playersBucketPlaystation = state.playersBucketPlaystation?.map(
            (playersBucketP) =>
              playersBucketP._id === action.payload._id
                ? action.payload
                : playersBucketP
          );
        }
      )
      .addCase(
        updatePlayerBucketPlaystation.rejected,
        (state, action: PayloadAction<any>) => {
          state.playerBucketLoading = false;
          state.error = action.payload;
        }
      );
  },
});

export default playersBucketSlice.reducer;
