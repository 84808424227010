import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import AuthProvider from "./hooks/AuthProvider";
import { useAppDispatch, useAppSelector } from "./hooks/useTypedSelector";
// import ProtectedRoute from "./components/shared/ProtectedRoute";
// import { Roles } from "./models/roles";
const VendorLayout = lazy(() => import("./components/Layouts/VendorLayout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./components/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/auth/ResetPassword"));
const VendorHome = lazy(() => import("./pages/VendorHome"));
const Customers = lazy(() => import("./pages/Customers"));
const Plans = lazy(() => import("./pages/Plans"));
const Addons = lazy(() => import("./pages/Addons"));
const Coupons = lazy(() => import("./pages/Coupons"));
const Vendors = lazy(() => import("./pages/Vendors"));
const OnBoarding = lazy(() => import("./pages/OnBoarding"));
const VendorDetails = lazy(() => import("./pages/VendorDetails"));
const SalesPersons = lazy(() => import("./pages/SalesPersons"));
const CustomerEntry = lazy(() => import("./pages/CustomerEntry"));
const CustomerCheckout = lazy(() => import("./pages/CustomerCheckout"));
const SalesPersonHome = lazy(() => import("./pages/SalesPersonHome"));
const ArcadeGameMachines = lazy(() => import("./pages/ArcadeGameMachines"));
const MachineCoins = lazy(() => import("./pages/MachineCoins"));
const MyAccount = lazy(() => import("./pages/MyAccount"));
const Referrals = lazy(() => import("./pages/Referrals"));
const CustomerTransactions = lazy(
  () => import("./components/Customers/CustomerTransactions")
);
const ConfigSettings = lazy(
  () => import("./components/MyAccount/ConfigSettings")
);
const VendorTransactions = lazy(() => import("./pages/Transactions"));
const ArcadeGameMachinesReport = lazy(
  () => import("./pages/ArcadeMachinesReport")
);
const CustomerHistory = lazy(
  () => import("./components/Customers/CustomerHistory")
);

const routesConfig = [
  {
    path: "/",
    element: (
      <Suspense fallback={<div className="container">Loading...</div>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/on-boarding",
    element: (
      <Suspense fallback={<div className="container">Loading...</div>}>
        <OnBoarding />
      </Suspense>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <Suspense fallback={<div className="container">Loading...</div>}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <Suspense fallback={<div className="container">Loading...</div>}>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    element: <VendorLayout />,
    children: [
      {
        path: "home",
        element: (
          <Suspense fallback={<div className="container">Loading...</div>}>
            <VendorHome />
          </Suspense>
        ),
      },
      {
        path: "my-account",
        element: (
          <Suspense fallback={<div className="container">Loading...</div>}>
            <MyAccount />
          </Suspense>
        ),
      },
      {
        path: "vendors",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <Vendors />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/plans",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <Plans />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/transactions",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <VendorTransactions />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <VendorDetails />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/sales/:salesPersonId",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <SalesPersonHome />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/addons",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <Addons />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/coupons",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <Coupons />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/settings",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <ConfigSettings />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/arcade-game-machines",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <ArcadeGameMachines />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/arcade-game-machines/report",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <ArcadeGameMachinesReport />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/machine-coins/:machineId",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <MachineCoins />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/other-users",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <SalesPersons />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/referrals",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <Referrals />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/customers",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <Customers />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/customer-entry",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <CustomerEntry />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/customer/:customerId",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <CustomerTransactions />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/customer/:customerId/history/:checkoutId",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <CustomerHistory />
              </Suspense>
            ),
          },
          {
            path: "view/:vendorId/customer/:customerId/checkout",
            element: (
              <Suspense fallback={<div className="container">Loading...</div>}>
                <CustomerCheckout />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routesConfig);

const App: React.FC = () => {
  const { userInfo } = useAppSelector((state) => state.userInfo);
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
